import { useQuery, useQueryClient } from 'react-query';
import {
  getEquipmentChange,
  getFlightSummary,
  getGanttConfigFilter,
  getFlightSummaryDetails,
  getAircraftDetailsByFlightNumber,
  getAircraftDetailsByRegistration,
  getAircraftCrewMembers,
} from '../../services/apiClient/airTrakManagerApi/airTrakManagerApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import usePrevious from '../usePrevious/usePrevious';
import { jsonEqual } from '../../lib/utils';
import { useAutoRefreshStore } from '../useAutoRefreshStore/useAutoRefreshStore';
import appSettings from '../../appSettings';
import { getDatetimeUtcNowString } from '../../lib/dateTimeUtils';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../lib/constants';

const moduleName = 'useAirTrakManagerQuery';

const baseConfig = {
  cacheTime: 0, // override default 5 minutes
  staleTime: 1 * 60 * 1000,
  retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
  refetchInterval: appSettings.REFRESH_INTERVAL,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: true,
  onError: (error) => {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'useAirTrakManagerQuery', createApiErrorTelemetryProperties(error));
  },
};

/**
 * @description Custom hook for retrieving data from AirtrakManager API gantt config endpoint
 * @param {string} filterData - filter json data.
 * @param {boolean} enabled - enable/disable the query.
 */
export const useGanttConfigQuery = (payload, enabled = true) => {
  const { showFeature } = useFeatureFlag();
  const showBlockPucksFeature = showFeature(Treatment.BLOCK_PUCKS);
  const autoRefreshFlags = useAutoRefreshStore();
  const enabledAutoRefresh = autoRefreshFlags?.ganttChartRefresh;
  const config = {
    ...baseConfig,
    enabled: enabled,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useGanttConfigQuery', createApiErrorTelemetryProperties(error));
    },
    onSuccess: () => {
      const time = getDatetimeUtcNowString('YYYY-MM-DD HH:mm:ss');
      console.log(`AirtrakManager API gantt data refreshed or fetched at ${time}z`);
    },
    select: (data) => {
      // Returns the ".data" property from the response
      return data.data;
    },
  };
  const { startDateUTC, endDateUTC, roleKey } = payload;

  return useQuery(
    ['airtrakmanager-gantt-config', startDateUTC, endDateUTC, roleKey],
    () => getGanttConfigFilter(payload, showBlockPucksFeature),
    config,
  );
};

/**
 * @description Custom hook for retrieving data from getEquipmentChange API
 * @param {object} flightLeg - flightLeg data.
 */
export const useEquipmentChange = (flightLeg) => {
  const previousData = usePrevious(flightLeg);
  const queryClient = useQueryClient();

  const config = {
    ...baseConfig,
    enabled: !Object.keys(flightLeg).find((key) => flightLeg[key] == undefined),
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useEquipmentChange', createApiErrorTelemetryProperties(error));
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('EquipmentChange');
    }
  }

  return useQuery(['EquipmentChange', flightLeg], () => getEquipmentChange(flightLeg), config);
};

/**
 * @description Custom hook for retrieving data from FlightSummary API
 * @param {object} flightLeg - flightLeg data.
 */
export const useFlightSummary = (flightLeg) => {
  const previousData = usePrevious(flightLeg);

  const queryClient = useQueryClient();
  const isEnabled = flightLeg != null && !Object.keys(flightLeg).find((key) => flightLeg[key] == undefined);

  const config = {
    ...baseConfig,
    enabled: isEnabled,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useFlightSummary', createApiErrorTelemetryProperties(error));
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('FlightSummary');
    }
  }

  return useQuery(['FlightSummary', flightLeg], () => getFlightSummary(flightLeg), config);
};

/**
 * @description Custom hook for retrieving data from FlightSummaryDetails API
 * @param {object} flightLeg - flightLeg data.
 */
export const useFlightSummaryDetails = (flightLeg) => {
  const autoRefreshFlags = useAutoRefreshStore();
  const previousData = usePrevious(flightLeg);
  const enabledAutoRefresh =
    autoRefreshFlags?.ganttChartRefresh &&
    flightLeg.origin != null &&
    flightLeg.destination != null &&
    flightLeg.flightNumber != null;
  const queryClient = useQueryClient();

  const config = {
    ...baseConfig,
    enabled: Object.keys(flightLeg).find((key) => flightLeg[key] == undefined) ? false : true,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(
        getApiErrorMessage(error),
        moduleName,
        'useFlightSummaryDetails',
        createApiErrorTelemetryProperties(error),
      );
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('FlightSummaryDetails');
    }
  }

  return useQuery(['FlightSummaryDetails', flightLeg], () => getFlightSummaryDetails(flightLeg), config);
};

/**
 * @description Custom hook for retrieving data from FlightSummaryDetails API
 * @param {object} flightLeg - flightLeg data.
 */
export const useAircraftDetails = (flightLeg, isGroundEvent = true, enabledQuery = false) => {
  const autoRefreshFlags = useAutoRefreshStore();
  const previousData = usePrevious(flightLeg);
  const retryQueryDelay = 2;
  const enabledAutoRefresh =
    autoRefreshFlags?.ganttChartRefresh &&
    ((flightLeg.origin != null && flightLeg.destination != null && flightLeg.flightNumber != null) ||
      !!flightLeg.aircraft);
  const queryClient = useQueryClient();

  const config = {
    ...baseConfig,
    retry: retryQueryDelay, // Will retry failed requests 2 times before displaying an error
    enabled: enabledQuery,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useAircraftDetails', createApiErrorTelemetryProperties(error));
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('AircraftDetails');
    }
  }

  return useQuery(
    ['AircraftDetails', flightLeg],
    () =>
      isGroundEvent
        ? getAircraftDetailsByRegistration(flightLeg.aircraft)
        : getAircraftDetailsByFlightNumber(flightLeg),
    config,
  );
};

/**
 * @description Custom hook for retrieving data from getAircraftCrewMembers API
 * @param {object} flightLeg - flightLeg data.
 */
export const useAircraftCrewMembers = (flightLeg) => {
  const autoRefreshFlags = useAutoRefreshStore();
  const previousData = usePrevious(flightLeg);
  const enabledAutoRefresh =
    autoRefreshFlags?.ganttChartRefresh &&
    flightLeg.origin != null &&
    flightLeg.destination != null &&
    flightLeg.flightNumber != null;
  const queryClient = useQueryClient();

  const config = {
    ...baseConfig,
    enabled: !Object.values(flightLeg).includes(undefined),
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(
        getApiErrorMessage(error),
        moduleName,
        'useAircraftCrewMembers',
        createApiErrorTelemetryProperties(error),
      );
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('AircraftCrewMembers');
    }
  }

  return useQuery(['AircraftCrewMembers', flightLeg], () => getAircraftCrewMembers(flightLeg), config);
};
