import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Toolbar from './Toolbar/Toolbar';
import { ViewWindowType, ResizeType } from '../../../../../lib/constants';
import useDraggableViewWindow from '../../../../../hooks/useDraggableViewWindow/useDraggableViewWindow';
import useResizableViewWindow from '../../../../../hooks/useResizableViewWindow/useResizableViewWindow';
import { useWindowManagerDispatch } from '../../../../../hooks/useWindowManagerStore/useWindowManagerStore';
import { calculateMinimizedPosition, dragEndCallback } from './utils';
import './ViewWindow.css';

const ViewWindow = ({
  variant,
  title = 'Untitled',
  isFocused = true,
  onFocusToWindow = () => {},
  children,
  onClose,
  windowPosition,
  id,
  isMinimized,
  minimizedIndex,
  isMaximized,
  isSwapModeActive,
}) => {
  const ref = useRef(null);
  const { setMinimizedView, removeMinimizedView, setMaximizedView, removeMaximizedView, setDraggedMiniView } =
    useWindowManagerDispatch();
  const [initialMinimizedPos, setInitialMinimizedPos] = useState({ x: 0, y: 0 });

  const canDragWindow = useMemo(() => {
    return variant === ViewWindowType.MULTI;
  }, [variant]);

  const canResizeWindow = useMemo(() => {
    return variant === ViewWindowType.MULTI && !isMinimized;
  }, [variant, isMinimized]);

  const draggableRef = useDraggableViewWindow({
    dragHandleElementId: 'toolbar',
    canDrag: canDragWindow,
    excludedElementIds: ['toolbar-icon', 'toolbar-icon-container'],
    dragEndCallback: (refCurrent) =>
      dragEndCallback(refCurrent, id, isMinimized, setDraggedMiniView, initialMinimizedPos),
  });

  const resizeTopHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP,
    containerRef: ref,
    canResize: canResizeWindow,
  });
  const resizeRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.RIGHT,
    containerRef: ref,
    canResize: canResizeWindow,
  });
  const resizeBottomHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM,
    containerRef: ref,
    canResize: canResizeWindow,
  });
  const resizeLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.LEFT,
    containerRef: ref,
    canResize: canResizeWindow,
  });
  const resizeBottomRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM_RIGHT,
    containerRef: ref,
    canResize: canResizeWindow,
  });
  const resizeBottomLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM_LEFT,
    containerRef: ref,
    canResize: canResizeWindow,
  });
  const resizeTopLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP_LEFT,
    containerRef: ref,
    canResize: canResizeWindow,
  });
  const resizeTopRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP_RIGHT,
    containerRef: ref,
    canResize: canResizeWindow,
  });

  let style;
  let className = '';

  switch (variant) {
    case ViewWindowType.MULTI:
      className += ' multi';
      style = {
        transform: 'translate(50px, 50px)',
        height: '500px',
        width: '800px',
        zIndex: 999 - windowPosition,
      };
      break;
    case ViewWindowType.SWAP_MODE:
      className += ' swap-mode';
      style = {
        transform: 'translate(0px, 0px)',
        height: '100%',
        width: '100%',
      };
      break;
    case ViewWindowType.SINGLE:
      className += ' single';
      style = {
        transform: 'translate(0px, 0px)',
        height: '100%',
        width: '100%',
      };
      break;
    default:
      break;
  }

  const attachRefs = useCallback(
    (el) => {
      ref.current = el;
      draggableRef.current = el;
    },
    [draggableRef],
  );

  const handleMaximize = useCallback(() => {
    if (isMaximized) {
      ref.current.style.transform = `translate(50px, 50px)`;
      ref.current.style.width = '800px';
      ref.current.style.height = '500px';
      ref.current.style.zIndex = 999 - windowPosition;
      removeMaximizedView(id);
    } else {
      ref.current.style.transform = `translate(0, 0)`;
      ref.current.style.width = '100%';
      ref.current.style.height = '100%';
      setMaximizedView(id);
    }
    removeMinimizedView(id);
  }, [isMaximized, id, removeMaximizedView, setMaximizedView]);

  useEffect(() => {
    if (isMinimized && minimizedIndex !== null) {
      const { x, y } = calculateMinimizedPosition(minimizedIndex);

      setInitialMinimizedPos({ x, y });

      ref.current.style.transform = `translate(${x}px, ${y}px)`;
      ref.current.style.width = '200px'; // Set to desired minimized width
      ref.current.style.height = '35px'; // Set to desired minimized height
      ref.current.style.zIndex = 999; // Ensure high z-index
    }
  }, [isMinimized, setMinimizedView, id, removeMaximizedView, minimizedIndex]);

  const handleMinimize = useCallback(() => {
    if (!isMinimized) {
      removeMaximizedView(id);
      setMinimizedView(id);
    }
  }, [isMinimized, setMinimizedView, id, removeMaximizedView, minimizedIndex]);

  const handleCloseClick = () => {
    onClose();
  };

  const handleFocusToWindow = () => {
    onFocusToWindow();
  };

  return (
    <div
      data-cy="view-window"
      className={`view-window${isFocused ? ' focused' : ''}${className}${isMaximized ? ' maximized' : ''}`}
      style={style}
      ref={attachRefs}
      onMouseDown={handleFocusToWindow}
    >
      {!isSwapModeActive && (
        <Toolbar
          title={`${title}${isFocused ? ' - Focused' : ''}`}
          variant={variant}
          onMaximize={handleMaximize}
          onMinimize={handleMinimize}
          onClose={handleCloseClick}
          isMinimized={isMinimized}
        />
      )}
      {<div className="view-window-content">{children}</div>}
      {canResizeWindow && (
        <div>
          <div className="resize-corner" id="resize-bottom-right" ref={resizeBottomRightHandleRef} />
          <div className="resize-corner" id="resize-bottom-left" ref={resizeBottomLeftHandleRef} />
          <div className="resize-corner" id="resize-top-right" ref={resizeTopRightHandleRef} />
          <div className="resize-corner" id="resize-top-left" ref={resizeTopLeftHandleRef} />
          <div className="resize-side" id="resize-right" ref={resizeRightHandleRef} />
          <div className="resize-side" id="resize-left" ref={resizeLeftHandleRef} />
          <div className="resize-side" id="resize-bottom" ref={resizeBottomHandleRef} />
          <div className="resize-side" id="resize-top" ref={resizeTopHandleRef} />
        </div>
      )}
    </div>
  );
};

ViewWindow.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocusToWindow: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  windowPosition: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  isMinimized: PropTypes.bool,
  minimizedIndex: PropTypes.number,
  isMaximized: PropTypes.bool,
  isSwapModeActive: PropTypes.bool,
};

export default ViewWindow;
