import React from 'react';
import PropTypes from 'prop-types';
import CrewTable from '../CrewTable/CrewTable';
import { Grid } from '@material-ui/core';

const CrewTables = ({ flightCrew, nextFlightCrew }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10} md={10} lg={5}>
        <CrewTable
          flightInfo={flightCrew.flight}
          crewInfoList={flightCrew.crewTrips}
          hasQualifications={true}
          columnNames={['', '', '', 'TRIP', 'QUALIFICATIONS']}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} lg={1}>
        &nbsp;
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={5}>
        <CrewTable
          flightInfo={nextFlightCrew.flight}
          crewInfoList={nextFlightCrew.crewTrips}
          hasQualifications={true}
          columnNames={['', '', '', 'TRIP', 'QUALIFICATIONS']}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} lg={1}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

CrewTables.propTypes = {
  flightCrew: PropTypes.object.isRequired,
  nextFlightCrew: PropTypes.object,
};

export default CrewTables;
