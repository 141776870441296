import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { getTransformValues } from '../../lib/htmlElementUtils/htmlElementUtils';
import { ResizeType } from '../../lib/constants';
import { getResizeTransform, getResizeWidth, getResizeHeight } from '../../lib/resizeUtils';

/**
 * Hook to make an element resizable.
 * @param {Object} props
 * @param {String} props.resizeType - The type of resize operation to perform
 * @param {React.RefObject} props.containerRef - A ref object to the container element that should be resized
 */
export default function useResizableViewWindow({ resizeType, containerRef, canResize }) {
  const resizeHandleElementRef = useRef();

  const [resizing, setResizing] = useState(false);
  const [resizeWidthOffset, setResizeWidthOffset] = useState(0);
  const [resizeHeightOffset, setResizeHeightOffset] = useState(0);
  const [transformOffset, setTransformOffset] = useState(null);

  /**
   * Handle the resize start event
   */
  const handleResizeStart = useCallback(
    (e) => {
      const { offsetWidth, offsetHeight } = containerRef.current;

      const { translateX, translateY } = getTransformValues(containerRef.current);
      const { clientX, clientY } = e;

      setResizing(true);
      setTransformOffset({
        x: clientX - translateX,
        y: clientY - translateY,
      });

      switch (resizeType) {
        case ResizeType.RIGHT:
        case ResizeType.BOTTOM:
        case ResizeType.BOTTOM_RIGHT:
          setResizeWidthOffset(offsetWidth - clientX);
          setResizeHeightOffset(offsetHeight - clientY);
          break;
        case ResizeType.LEFT:
        case ResizeType.TOP:
        case ResizeType.TOP_LEFT:
          setResizeWidthOffset(offsetWidth + clientX);
          setResizeHeightOffset(offsetHeight + clientY);
          break;
        case ResizeType.BOTTOM_LEFT:
          setResizeWidthOffset(offsetWidth + clientX);
          setResizeHeightOffset(offsetHeight - clientY);
          break;
        case ResizeType.TOP_RIGHT:
          setResizeWidthOffset(offsetWidth - clientX);
          setResizeHeightOffset(offsetHeight + clientY);
          break;
        default:
          break;
      }
    },
    [resizeType, containerRef],
  );

  /**
   * Debounced version of the handleResize function
   */
  const debouncedHandleResize = useMemo(() => {
    const handleResize = (e) => {
      if (!resizing || !canResize) return;

      const { translateX: currentTranslateX, translateY: currentTranslateY } = getTransformValues(containerRef.current);
      const rect = containerRef.current.getBoundingClientRect();
      const currentWidth = rect.width;
      const currentHeight = rect.height;
      const { clientX, clientY } = e;
      const width = getResizeWidth(resizeType, parseInt(currentWidth, 10), clientX, resizeWidthOffset, transformOffset);
      const height = getResizeHeight(
        resizeType,
        parseInt(currentHeight, 10),
        clientY,
        resizeHeightOffset,
        transformOffset,
      );

      const { translateX, translateY } = getResizeTransform(
        resizeType,
        width,
        height,
        currentTranslateX,
        currentTranslateY,
        clientX,
        clientY,
        transformOffset,
      );

      // Set width, height, and transform (left and top) values
      containerRef.current.style.width = `${width}px`;
      containerRef.current.style.height = `${height}px`;
      containerRef.current.style.transform = `translate(${translateX}px, ${translateY}px)`;
    };

    return debounce(handleResize, 5);
  }, [containerRef, resizing, resizeWidthOffset, resizeHeightOffset, resizeType, transformOffset]);

  /**
   * Handle the resizing event
   */
  const handleResize = useCallback(
    (e) => {
      e.preventDefault();
      debouncedHandleResize(e);
    },
    [debouncedHandleResize],
  );

  /**
   * Handle the resize end event
   */
  const handleResizeEnd = useCallback(() => {
    setResizing(false);
  }, []);

  /**
   * Side-effect to add event listeners when the component mounts and remove them when it unmounts
   */
  useEffect(() => {
    const refCopy = resizeHandleElementRef?.current;

    if (!refCopy || !Object.values(ResizeType).includes(resizeType)) return;

    refCopy.addEventListener('mousedown', handleResizeStart);

    if (resizing) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleResizeEnd);
    }

    return () => {
      refCopy.removeEventListener('mousedown', handleResizeStart);
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [handleResizeStart, handleResize, handleResizeEnd, resizing, resizeHandleElementRef, resizeType]);

  return resizeHandleElementRef;
}
